import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import axios from 'axios'
import SecureLS from 'secure-ls'
import { authStore } from '@/stores/auth'
import { subtotalStore } from '@/stores/subtotal'
import { headerStore } from '@/stores/header'
import { errorNotification, successNotification } from '@/functions/notification'
import { Option } from '@/types/extra/option'

const ls = new SecureLS({ isCompression: false })

export const extraStore = defineStore('extra', () => {
  const loading = ref(false)
  const savingId = ref(0)
  const removingId = ref(0)
  const options = ref<Option[]>([])
  const agreeToTerms = ref(false)
  const creatingOrder = ref(false)

  const isInternalTestAccount = computed(() => {
    return ls.get('demoAccount').toLowerCase() === authStore().user.email.toLowerCase() || ls.get('oldDemoAccount').toLowerCase() === authStore().user.email.toLowerCase()
  })

  const selectedNotOrderedOptions = computed(() => {
    let notOrderedOptions = false
    options.value.forEach((o:Option) => {
      if (o.selected && o.inOrder === null) {
        notOrderedOptions = true
      }
    })
    return notOrderedOptions
  })

  const optionsAvailable = computed(() => {
    let available = false
    options.value.forEach((o:Option) => {
      if (o.inOrder === null && !o.closed) {
        available = true
      }
    })
    return available
  })

  function getOptions () : void {
    loading.value = true
    axios.get(process.env.VUE_APP_API_URL + '/extra/options').then((r) => {
      options.value = r.data
    }).finally(() => {
      loading.value = false
    })
  }

  function selectOption (so:Option) : void {
    savingId.value = so.id
    axios.post(process.env.VUE_APP_API_URL + '/extra/option/store', { oid: so.id }).then(() => {
      subtotalStore().getSubtotals()
      options.value.forEach((o:{ id:number, selected:boolean }) => {
        if (o.id === so.id) {
          o.selected = true
        }
      })
    }).finally(() => {
      savingId.value = 0
    })
  }

  function unselectOption (uso:Option) : void {
    removingId.value = uso.id
    axios.post(process.env.VUE_APP_API_URL + '/extra/option/remove', { oid: uso.id }).then(() => {
      subtotalStore().getSubtotals()
      options.value.forEach((o:Option) => {
        if (o.id === uso.id) {
          o.selected = false
        }
      })
    }).finally(() => {
      removingId.value = 0
    })
  }

  function createOrder () : void {
    if (agreeToTerms.value) {
      creatingOrder.value = true
      axios.get(process.env.VUE_APP_API_URL + '/extra/order').then((r) => {
        options.value = r.data
        agreeToTerms.value = false
        headerStore().getOrdersReceived()
        successNotification('Bestelling geplaatst!', 'Uw bestelling voor de extra opties is succesvol geplaatst.')
      }).catch(() => {
        errorNotification('Bestelling NIET geplaatst!', 'Er is iets mis gegaan tijdens het plaatsen van uw bestelling. Probeer het nogmaals.')
      }).finally(() => {
        creatingOrder.value = false
      })
    }
  }

  return { loading, savingId, removingId, options, agreeToTerms, creatingOrder, isInternalTestAccount, selectedNotOrderedOptions, optionsAvailable, getOptions, selectOption, unselectOption, createOrder }
})
